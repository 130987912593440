



































































































import { Component, Mixins } from 'vue-property-decorator'
import dataMixins from '@/mixins/clearData.ts.mixins'
import { configOptionsV2 } from '@/ajax/requestOptions';
import HeaderBox from '@/components/HeaderBox.vue'
import { Message } from 'element-ui';
import cache from '@/common/cache';

interface DefaultProps {
  children: string
  label: string
}

interface AddRole {
  name: string
}

interface EditRole extends AddRole {
  id: string
}

@Component({
  components: {
    HeaderBox
  }
})
export default class RolePermission extends Mixins(dataMixins) {
  readonly defaultProps: DefaultProps = {
    children: 'children_perm',
    label: 'name',
  }
  roleList: any = []
  permissionsList = []
  rolePermissionList = []
  roleName: string = ''
  roleIndex: string = ''
  isAddBox: boolean = false
  dialogDeleteVisible: boolean = false
  id: string = ''
  index: string = ''
  activeIndex:number = 0
  idx: string = ''
  deleteName: string = ''
  checked: boolean = true
  addRole: AddRole = {
    name: '',
  }
  // 修改角色名称
  editRole: EditRole = {
    name: '', // 长度1-8，必填
    id: '',
  }
  timeoutId = null
  isTapDone: boolean = false

  mounted() {
    this.checked = false;
    this.getAllRoles();
    this.getAllPermissions();
  }

  async initData () {
    await this.getAllRoles();
    this.checked = false;
    await this.getAllPermissions();
  }

  async getAllRoles() {
    // 获取全部角色列表
    const { code, data } = await this.$rest.operate.getRoles(null);
    if (code !== 0) return;
    this.roleList = data;
    // 初始化第一个角色权限
    this.handleSetCheckedKeys(0);
  }

  async getAllPermissions() {
    // 获取全部权限层级列表
    const { code, data } = await this.$rest.operate.getPermissions(null);
    if (code !== 0) return;
    this.permissionsList = [data];
  }

  handleMouseOver(index) {
    this.roleIndex = index;
  }

  handleMouseOut(index) {
    this.roleIndex = '';
  }

  handleCheck(data, node) {
    const $tree: any = this.$refs.tree;
    data.parent_id && $tree.setChecked(data.parent_id, true);
    this.handleCheckChange();
  }

  handleCheckChange() {
    const $tree:any = this.$refs.tree;
    $tree.getHalfCheckedKeys().forEach(key => {
      $tree.setChecked(key, true);
    });
  }

  // 是否有子元素
  hasChildren(node) {
    return node.children_perm && node.children_perm.length;
  }

  async handleSetCheckedKeys(index) {
    this.activeIndex = index;
    this.index = index;
    this.idx = index;
    const id = this.roleList[index].role_id;
    this.roleName = this.roleList[index].name;
    const { code, data } = await this.$rest.operate.getRolePermission(id);

    if (code !== 0) return;
    this.timeoutId = setTimeout(() => {
      const $tree: any = this.$refs.tree;
      // 给选中角色权限打钩
      $tree && $tree.getCheckedKeys().forEach(key => {
        $tree.setChecked(key, false);
      });
      data.forEach(val => {
        $tree && $tree.setChecked(val, true);
      });
      this.rolePermissionList = data;
    }, 500)
    // 页面滚回去
    document.documentElement.scrollTop = 0;
  }

  handleHideBox () {
    this.id = '';
    this.isAddBox = false;
  }

  // 新增角色
  handleAddNewRole() {
    this.addRole.name = '';
    this.isAddBox = true;
  }

  async handleAddSubmit() {
    if (!this.addRole.name) return
    const params = this.addRole;
    this.isTapDone = true
    const { code, message } = await this.$rest.operate.postRoles(params);
    this.isTapDone = false
    if (code === 0) {
      // 页面上动态新增数据
      this.roleList.splice(0, 0, params);
      // 清除输入框文字
      this.addRole.name = '';
      // 重新加载页面
      this.initData();
      this.$message({
        type: 'success',
        message: '已新增',
      });
    }
    this.isAddBox = false;
  }

  // 修改角色名称
  handleEditRoleName(index) {
    this.id = index;
    this.editRole.id = this.roleList[index].role_id;
  }

  async handleEditSubmit(index) {
    this.id = '';
    if (!this.editRole.name) return
    this.isTapDone = true
    const { code } = await this.$rest.operate.updateRoles(this.editRole.id, {
      name: this.editRole.name
    });
    this.isTapDone = false
    if (code !== 0) return;
    // 动态更新页面角色名字
    this.roleList[index].name = this.editRole.name;
    // 动态更新右边上角色更改后名字
    this.roleName = this.editRole.name;
    // 清除输入框文字
    this.editRole.id = ''
    this.editRole.name = ''
    this.$message({
      type: 'success',
      message: '已修改',
    });
  }

  // 删除角色
  handleDeleteRole(index) {
    this.idx = index;
    this.deleteName = this.roleList[index].name;
    this.dialogDeleteVisible = true;
  }

  async handleDeleteSubmit() {
    this.isTapDone = true;
    const { code } = await this.$rest.operate.deleteRoles(this.roleList[this.idx].role_id);
    this.dialogDeleteVisible = false;
    this.isTapDone = false
    if (code !== 0) return;
    // 页面上动态删除该条数据
    this.roleList.splice(this.idx, 1);
    // 重新加载页面
    this.initData();
    this.$message({
      type: 'success',
      message: '已删除',
    });
  }

  // 更新角色的权限
  async handleSave() {
    const $tree: any = this.$refs.tree;
    const idList = $tree.getCheckedNodes().map(v => v.perm_id);
    const id = this.idx ? this.roleList[this.idx].role_id : this.roleList[0].role_id
    this.isTapDone = true
    const { code } = await this.$rest.operate.postRolesPermissions(id, {
      permission_ids: idList
    });
    this.isTapDone = false
    if (code !== 0) return;

    const res = await this.$rest.operate.getEmployeePermission();
    if (res.code === 0) {
      cache.setLocalStorageData('auth_list', res.data);
    }
    this.$message({
      showClose: true,
      message: '保存成功',
      type: 'success',
    });
  }

  beforeRouteLeave(to, from, next) {
    this.handleSetCheckedKeys(0);
    next();
  }

  beforeDestroy () {
    clearTimeout(this.timeoutId)
  }
};
