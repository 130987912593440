

















































































































import { Component, Mixins } from 'vue-property-decorator'
import dataMixins from '@/mixins/clearData.ts.mixins'
import auth from '@/common/auth'
import HeaderBox from '@/components/HeaderBox.vue'
import cache from '@/common/cache';

interface searchParams {
  page: number
  count: number
  status: number
  center_id: string
  role: string
  name: string
  mobile: string
}
interface EmployeeForm {
  name: string
  mobile: string
}

@Component({
  components: {
    HeaderBox
  },
  filters: {
    formatStatus (val) {
      return val === 1 ? '正常' : '停用'
    }
  }
})
export default class FrameWork extends Mixins(dataMixins) {
  rules = {
    name: {
      required: true,
      validator: auth.checkInput({
        nullValue: '姓名不能为空',
        haveValue: '请输入1~10个汉字、数字、字母',
        regex: 'regexp_chinese_ten'
      }),
      trigger: 'blur'
    },
    mobile: {
      required: true,
      validator: auth.checkInput({
        nullValue: '手机号码不能为空',
        haveValue: '请输入正确的手机号码',
        regex: 'regexp_mobile'
      }),
      trigger: 'blur'
    },
  }
  activeName: number = 0
  loading: boolean = false
  tableData = []
  searchParams: searchParams = {
    page: 1,
    count: 10,
    status: 0,
    center_id: '',
    role: '',
    name: '',
    mobile: '',
  }
  totalNum: number = 0
  // 防止重复提交
  isTapDone: boolean = false
  operateTitle: string = '新增'
  dialogEmployeeVisible: boolean = false
  dialogRolesVisible: boolean = false
  employeeForm: any = {
    name: '',
    mobile: ''
  }
  checkedRoles = []
  allRoles = []
  employee_id = null

  created() {
    this.getOrganization();
  }

  async getOrganization () {
    this.loading = true;
    const { code, data } = await this.$rest.operate.getOrganization(this.searchParams);
    this.loading = false;
    if (code === 0) {
      this.tableData = data.infos;
      this.totalNum = data.total_count;
    }
  }

  async getAllRoles() {
    const {code, data} = await this.$rest.operate.getRoles();
    if (code === 0) {
      this.allRoles = data;
    }
  }

  handelSearchDeptEmployees () {
    this.searchParams.page = 1;
    this.getOrganization();
  }

  handleReset () {
    this.searchParams.page = 1;
    this.searchParams.name = '';
    this.searchParams.mobile = '';
    this.searchParams.role = '';
    this.getOrganization();
  }

  handleActiveClick (val) {
    this.searchParams.status = +val.name;
    this.getOrganization();
  }

  handleOperate (val, { name = '', mobile = '', id = 0 } = {}) {
    this.employeeForm = {
      name: '',
      mobile: ''
    }
    this.dialogEmployeeVisible = true;
    if (val == 'add') {
      this.operateTitle = '新增';
    } else {
      this.operateTitle = '编辑';
      this.employeeForm.name = name;
      this.employeeForm.mobile = mobile;
      this.employeeForm = Object.assign(this.employeeForm, { id });
    }
  }

  async handleOperateEmployee () {
    (this.$refs.employeeForm as any).validate(async (valid) => {
      if (!valid) {
        return;
      }
      let res = null;
      this.isTapDone = true;
      if (this.operateTitle === '新增')  {
        res = await this.$rest.operate.postEmployee(this.employeeForm);
      } else {
        res = await this.$rest.operate.updateEmployee(this.employeeForm.id, {
          mobile: this.employeeForm.mobile,
          name: this.employeeForm.name
        });
      }
      this.isTapDone = false;
      if (res.code !== 0) return;
      this.dialogEmployeeVisible = false;
      this.searchParams.page = 1;
      this.getEmployeePermission();
      this.getOrganization();
    })
  }

  async getEmployeePermission () {
    const resPermission = await this.$rest.operate.getEmployeePermission();
    if (resPermission.code === 0) {
      cache.setLocalStorageData('auth_list', resPermission.data);
    }
  }

  handleUpdateRoles (row) {
    this.employee_id = row.id
    this.dialogRolesVisible = true;
    this.checkedRoles = row.role_ids;
    this.getAllRoles();
  }

  async handleUpdateRole () {
    this.isTapDone = true;
    const { code } = await this.$rest.operate.updateRole(this.employee_id, {
      role_ids: this.checkedRoles
    })
    this.isTapDone = false;
    if (code !== 0) return;
    this.dialogRolesVisible = false;
    this.getOrganization();
  }

  handleSetEmployee (val, { id, name }) {
    const statusName  = val === 'start' ? '启用' : '停用';
    this.$confirm(`你确定将【${name}】${statusName}?`, statusName, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      let res = null;
      if (val === 'start') {
        res = await this.$rest.operate.startEmployee(id);
      } else {
        res = await this.$rest.operate.stopEmployee(id);
      }
      if (res.code === 0) {
        this.getOrganization();
      }
    }).catch(() => {}); // catch 必须要，否则点击取消时会提示没有绑定取消事件
  }

  handleCurrentChange () {
    this.getOrganization();
  }
}
